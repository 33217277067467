<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-09-11 08:36:51
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-11-08 13:47:39
 * @FilePath: \official_website\src\views\contact\Contact.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <div id="contact">
    <Header></Header>
    <div class="contact-content">
      <div class="contact-img">
        <div class="contact-title wow animate__animated animate__fadeInDown">
          人才招募
        </div>
      </div>
      <div class="contact-box">
        <div class="recruit-title wow animate__animated animate__bounce">
          2023年招聘需求计划表
        </div>
        <div class="recruit-box">
          <ul class="recruit">
            <li
              class="recruit-list wow animate__animated animate__fadeInUp"
              v-for="(items, indexs) in recruitmentList"
              :key="indexs"
              :ref="'recruitList' + indexs"
            >
              <div class="header">
                <div class="position">{{ items.jobName }}</div>
                <div class="department">{{ items.jobType }}</div>
              </div>
              <div class="responsibility">
                <div class="title">岗位职责</div>
                <p class="post-box" style="white-space: pre-line">
                  {{ items.detail }}
                </p>
              </div>
              <div class="job-request">
                <div class="title">岗位要求</div>
                <p class="job-box" style="white-space: pre-line">
                  {{ items.edu }}
                </p>
              </div>
              <div class="demand-area">
                <div class="title">需求区域</div>
                <div class="demand-box">
                  <ul class="demand">
                    <li
                      class="demand-list"
                      v-if="items.area1 && items.number1 && items.provice1"
                    >
                      <div class="left">
                        <span class="area">{{ items.area1 }}</span>
                        <span class="number">{{ items.number1 }}人</span>
                      </div>
                      <div class="right">{{ items.provice1 }}</div>
                    </li>
                    <li
                      class="demand-list"
                      v-if="items.area2 && items.number2 && items.provice2"
                    >
                      <div class="left">
                        <span class="area">{{ items.area2 }}</span>
                        <span class="number">{{ items.number2 }}人</span>
                      </div>
                      <div class="right">{{ items.provice2 }}</div>
                    </li>
                    <li
                      class="demand-list"
                      v-if="items.area3 && items.number3 && items.provice3"
                    >
                      <div class="left">
                        <span class="area">{{ items.area3 }}</span>
                        <span class="number">{{ items.area3 }}人</span>
                      </div>
                      <div class="right">{{ items.provice3 }}</div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="buttons">
                <span
                  v-if="!items.foldFlag"
                  @click="viewMore(indexs)"
                  class="more"
                  >查看更多</span
                >
                <span v-else class="retract" @click="retract(indexs)"
                  >收起</span
                >
              </div>
            </li>
          </ul>
        </div>

        <div class="pagination-box">
          <el-pagination
            background
            layout="total, prev, pager, next"
            :total="count"
            :current-page.sync="currentPage"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
    
<script>
import Header from "../componets/Header.vue";
import Footer from "../componets/Footer.vue";
import api from "../../api/api";

export default {
  name: "",
  props: {},
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      recruitmentList: [],
      count: 0,
      currentPage: 1
    };
  },
  created() {
    // this.recruitmentList.forEach((item) => {
    //   item.foldFlag = false
    // })
    
    this.getRecruitLists();

    this.getRecruitCounts();
  },
  mounted() {
     this.$nextTick(() => {
      new this.$wow.WOW({
        boxClass: "wow", // 动画元件css类（默认为wow）
        animateClass: "animate__animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      }).init();
    });
  },
  watch: {},
  methods: {
    resetFlod() {
      this.recruitmentList.forEach((item, index) => {
        item.foldFlag = false
        this.$refs[`recruitList${index}`][0].style.height = "10.5rem"
        this.$refs[`recruitList${index}`][0].style.overflow = "hidden"
      })
    },
    viewMore(index) {
      this.resetFlod()
      this.recruitmentList.[index].foldFlag = !this.recruitmentList.[index].foldFlag
      if(this.recruitmentList.[index].foldFlag) {
        // console.log(this.$refs[`recruitList${index}`][0].style);
        this.$refs[`recruitList${index}`][0].style.height = "initial"
        this.$refs[`recruitList${index}`][0].style.overflow = "initial"
        this.$refs[`recruitList${index}`][0].style.transition = "height 1s linear"
      }
      this.recruitmentList = [...this.recruitmentList]
    },
    retract(index) {
      this.resetFlod()
      // this.recruitmentList.[index].foldFlag = !this.recruitmentList.[index].foldFlag
      if(!this.recruitmentList.[index].foldFlag) { 
        this.$refs[`recruitList${index}`][0].style.height = "10.5rem"
        this.$refs[`recruitList${index}`][0].style.overflow = "hidden"
        this.$refs[`recruitList${index}`][0].style.transition = "height 1s linear"
      }
      this.recruitmentList = [...this.recruitmentList]
    },
    // 获取招聘分类列表
    getRecruitLists(page = 1) {
      api.getRecruitByPage(page).then(res => {
        let result = res.data.models
        // console.log(result);
        result.forEach((item) => {
          if(item.remark) {
            item.demandArea = JSON.parse(item.remark)
            item.area1 = item.demandArea[0].area1
            item.number1 = item.demandArea[0].number1
            item.provice1 = item.demandArea[0].provice1
            item.area2 = item.demandArea[1].area2
            item.number2 = item.demandArea[1].number2
            item.provice2 = item.demandArea[1].provice2
            item.area3 = item.demandArea[2].area3
            item.number3 = item.demandArea[2].number3
            item.provice3 = item.demandArea[2].provice3
          } else {
            item.demandArea = []
          }
          
          item.foldFlag = false
        })

        this.recruitmentList = result

        // this.$nextTick(() => {
        //   new this.$wow.WOW({
        //     boxClass: "wow", // 动画元件css类（默认为wow）
        //     animateClass: "animate__animated", //动画css类（默认为animated）
        //     offset: 0, //到元素距离触发动画（当默认为0）
        //     mobile: true, //在移动设备上触发动画（默认为true）
        //     live: true, //对异步加载的内容进行操作（默认为true）
        //   }).init();
        // });
        
      });
    },
     // 获取招聘总条数
    getRecruitCounts(){
      api.getRecruitCount().then(res => {
        this.count = res.data.models[0]
      }).catch(error => {

      });
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getRecruitLists(val)
    }
  },
};
</script>
    
<style lang="scss">
#contact {
  .contact-content {
    padding-top: 5rem;
    .contact-img {
      width: 100%;
      height: 28rem;
      background: url("../../assets/image/contact/zhpin.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      position: relative;
      .contact-title {
        position: absolute;
        top: 40%;
        left: 10%;
        transform: translateY(-50%);
        color: #fff;
        font-size: 2.2rem;
        font-family: "SourceHanSansSC-Medium";
      }
    }
    .contact-box {
      padding: 5% 4% 2%;
      .recruit-title {
        font-size: 2rem;
        font-family: "SourceHanSansSC-Medium";
        text-align: center;
      }
      .recruit-box {
        padding: 5% 4% 2%;
        .recruit {
          .recruit-list {
            position: relative;
            padding: 2rem;
            background: #f5f5f5;
            border-left: 0.625rem solid #0b847b;
            border-radius: 0.625rem;
            margin-bottom: 1.5rem;
            height: 10.5rem;
            overflow: hidden;
            .header {
              display: flex;
              justify-content: space-between;
              margin-bottom: 1.2rem;
              .position {
                font-size: 1.4rem;
                font-family: "SourceHanSansSC-Medium";
              }
              .department {
                padding: 0.3rem 0.625rem;
                background-color: #e6e6e6;
                color: #666;
                font-size: 1rem;
                font-family: "OPlusSans3-Medium";
              }
            }
            .responsibility {
              margin-bottom: 1.2rem;
              .title {
                font-size: 1.2rem;
                font-family: "SourceHanSansSC-Medium";
                margin-bottom: 1rem;
              }
              .post-box {
                font-size: 0.9rem;
                font-family: "OPlusSans3-Medium";
                line-height: 2rem;
                color: #666;
              }
            }
            .job-request {
              margin-bottom: 1.2rem;
              .title {
                font-size: 1.2rem;
                font-family: "SourceHanSansSC-Medium";
                margin-bottom: 1rem;
              }
              .job-box {
                font-size: 0.9rem;
                font-family: "OPlusSans3-Medium";
                line-height: 2rem;
                color: #666;
              }
            }
            .demand-area {
              margin-bottom: 1.2rem;
              .title {
                font-size: 1.2rem;
                font-family: "SourceHanSansSC-Medium";
                margin-bottom: 1rem;
              }
              .demand-box {
                font-size: 0.9rem;
                font-family: "OPlusSans3-Medium";
                .demand {
                  display: flex;
                  .demand-list {
                    padding: 0.5rem 1rem;
                    background: #dde9e8;
                    border-radius: 3.5rem;
                    display: flex;
                    align-items: center;
                    margin-right: 2rem;
                    .left {
                      margin-right: 1rem;
                      span {
                        display: inline-block;
                      }
                      .area {
                        font-size: 1rem;
                        font-family: "SourceHanSansSC-Medium";
                      }
                      .number {
                        margin: 0 0.5rem;
                        color: #0b847b;
                      }
                    }
                    .right {
                      color: #666;
                    }
                  }
                }
              }
            }
            .buttons {
              position: absolute;
              right: 2rem;
              bottom: 1rem;
              display: flex;
              justify-content: flex-end;
              color: #0b847b;
              font-size: 0.9rem;
              font-family: "OPlusSans3-Medium";
              span {
                cursor: pointer;
              }
            }
          }
        }
      }
      .pagination-box {
        display: flex;
        justify-content: center;
        .el-pager li,
        .el-pagination button {
          height: 2.25rem;
          line-height: 2.25rem;
          min-width: 2.25rem;
          font-size: 0.95rem;
        }
        .el-pagination .btn-prev .el-icon,
        .el-pagination .btn-next .el-icon {
          font-size: 0.95rem;
        }
      }
    }
  }
  .footer {
    width: 100%;
    height: 100%;
    background: #f5f5f5;
    padding: 6.25rem 8% 3.125rem;
  }
}
</style>